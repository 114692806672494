<template>
  <el-dialog
    class="add-transfer-major-dialog"
    title="转专业登记"
    :visible.sync="show"
    :close-on-click-modal="false"
    width="720px"
    :before-close="cancel">
    <el-descriptions :column="2" border>
      <el-descriptions-item label-class-name="descriptions-item__label" label="姓名">
        {{ userInfo.stuName }}
      </el-descriptions-item>
      <el-descriptions-item label-class-name="descriptions-item__label" label="性别">
        {{ userInfo.sexType === 1 ? '男' : (userInfo.sexType === 2 ? '女' : '') }}
      </el-descriptions-item>
      <el-descriptions-item label-class-name="descriptions-item__label" label="年级">
        {{ userInfo.grade }}
      </el-descriptions-item>
      <el-descriptions-item label-class-name="descriptions-item__label" label="原校区">
        {{ userInfo.campusName }}
      </el-descriptions-item>
      <el-descriptions-item label-class-name="descriptions-item__label" label="原院系">
        {{ userInfo.collegeName }}
      </el-descriptions-item>
      <el-descriptions-item label-class-name="descriptions-item__label" label="原专业">
        {{ userInfo.majorName }}
        【{{ $http.getParamName(userInfo.majorType, $store.state.systemParam.majorType) }}】
      </el-descriptions-item>
    </el-descriptions>
    <div v-if="userInfo.originalMajorId!==userInfo.majorId" class="warning-tip">
      该生已转过专业，转前专业为：{{ userInfo.originalMajorName }}
    </div>
    <el-form class="mt-1" ref="form" :model="formData" :rules="rules" label-width="110px">
      <el-row>
        <el-col :span="12">
          <el-form-item label="转入院系">
            <el-select v-model="collegeId" @change="changeCollege" filterable clearable>
              <el-option
                v-for="{collegeName, id} in collegeList"
                :label="collegeName"
                :value="id"
                :key="id" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="转入专业" prop="toMajorId">
            <tool-tips-group is-flex tips-content="只能转到跟原专业的【专业类型】相同的专业。">
              <el-select ref="majorSelectRef" v-model="formData.toMajorId" :disabled="!comptMajorList.length" filterable clearable
                         @change="handleCampus">
                <el-option
                  v-for="{majorName, id} in comptMajorList"
                  :label="majorName"
                  :value="id"
                  :key="id" />
              </el-select>
            </tool-tips-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="转入校区" prop="toCampusId">
            <el-select disabled v-model="formData.toCampusId" placeholder="请先选择转入专业">
              <el-option
                v-for="{campusName, id} in campusList"
                :label="campusName"
                :value="id"
                :key="id" />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template #footer>
      <cancel-popover ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag" />
      <el-button type="primary" @click="save">转专业</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { updateMajor } from '@/api/student'
import dialog from '@/vue/mixins/dialog'
import CampusSchMajorClasses from '@/vue/mixins/campusSchMajorClasses'
import { getCampusMajorAndGradeApi } from '@/api/major'
import { throttle } from 'lodash'

export default {
  mixins: [dialog, CampusSchMajorClasses],
  props: {
    addData: {
      type: Object
    }
  },
  data() {
    return {
      formData: {
        toCampusId: '',
        userId: '', // 用户id
        toMajorId: '', // 转后id
        clearFlag: false
      },
      collegeId: this.$store.state.login.userInfo.collegeId || '',
      userInfo: {
        stuName: '',
        sexType: '',
        collegeName: '',
        campusName: '',
        campusId: '',
        majorName: '',
        majorType: '',
        majorId: '',
        userId: '',
        originalMajorId: '',
        originalMajorName: '',
        grade: '',
        origin: {
          collegeId: null
        }
      },
      rules: {
        toMajorId: {
          required: true,
          message: '请选择要转专业',
          trigger: 'blur'
        }
      }
    }
  },
  computed: {
    comptMajorList() {
      return this.majorList.filter(({ majorType }) => majorType === this.userInfo.majorType)
    }
  },
  async mounted() {
    await Promise.all([
      this.getCampusList(),
      this.getCollegeList()
    ])
    if (this.$store.state.login.userInfo.collegeId) {
      await this.getMajorList()
    }
    this.$nextTick(() => {
      this.formData.userId = this.userInfo.userId
      this.setInitData()
    })
  },
  methods: {
    // 选择院系
    changeCollege(collegeId) {
      this.formData.toMajorId = ''
      this.getMajorList({ collegeId }).then(() => {
        this.majorList = this.majorList.filter(({ majorType }) => majorType === this.userInfo.majorType)
      })
    },
    save: throttle(function() {
      if (this.loadData.loading) {
        this.$message.error('请勿重复提交!')
        return
      }
      if (this.formData.toMajorId === this.userInfo.majorId) {
        this.$message.warning('专业相同不能互转')
        return false
      }
      // 判断表单是否有更新
      this.compareData()
      if (!this.updateFlag) {
        this.$message.error('表单未被修改!')
        return
      }
      this.$refs.form.validate((valid) => {
        if (!valid) {
          this.$message('未通过校验,可能是漏填必填项!')
          return false
        }
        const _msg = this.userInfo.originalMajorId === this.userInfo.majorId ? '' : `<br><span class="font-grey">(该生已转过专业，转前专业为：${this.userInfo.originalMajorName}。</span>）<br>`
        this.$confirm(`此操作将调整${this.userInfo.stuName}的专业到<b class="font-danger">${this.$refs.majorSelectRef.selectedLabel}</b>，${_msg}是否继续?`, '转专业确认', {
          dangerouslyUseHTMLString: true
        }).then(async () => {
          this.loadData.loading = true
          try {
            /* 保存参数过滤规则
            *  1.为null 的不提交
            *  2.空数组的不提交
            *  3.空字符串的不提交（原本有值改空字符串的提交）
            *  4.数字0提交
            * */
            const _formData = {}
            Object.keys(this.formData).filter(key => (!Array.isArray(this.formData[key]) && !!this.formData[key]) || !!this.formData[key]?.length || this.formData[key] === 0 || this.formData[key] !== this.initData[key]).forEach(k => {
              _formData[k] = this.formData[k]
            })
            await updateMajor(_formData)
            let _successMsg = '专业调整成功！'
            if (this.collegeId === this.userInfo.origin.collegeId) {
              this.$message.success(_successMsg)
            } else {
              _successMsg = `${_successMsg}<b>${this.userInfo.stuName}</b>的原宿舍已清空，<b class="font-danger"">请及时办理宿舍转移手续</b>。`
              this.$confirm(_successMsg, '温馨提醒', {
                dangerouslyUseHTMLString: true
              }).then()
            }
          } catch (e) {
          } finally {
            this.loadData.loading = false
            this.closed()
          }
        }).catch()
      })
    }, 1000),
    // 根据专业和学生年级获取校区
    async handleCampus() {
      try {
        const _query = {
          grade: this.userInfo.grade,
          majorId: this.formData.toMajorId
        }
        const res = await getCampusMajorAndGradeApi(_query)
        this.formData.toCampusId = res.data.campusId
      } catch (e) {

      }
    }
  }
}
</script>
