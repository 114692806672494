var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-view',{staticClass:"transfer-record",attrs:{"total":_vm.total,"query-info":_vm.queryInfo},on:{"update:queryInfo":function($event){_vm.queryInfo=$event},"update:query-info":function($event){_vm.queryInfo=$event},"reload-table":_vm.renderTable},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('view-search-form',{attrs:{"keyword-placeholder":"姓名、录取编号","queryInfo":_vm.queryInfo,"tool-list":['grade', 'keyword']},on:{"update:queryInfo":function($event){_vm.queryInfo=$event},"update:query-info":function($event){_vm.queryInfo=$event},"on-search":function($event){return _vm.renderTable(1)}}}),_c('div',{staticClass:"header-button"},[_c('el-button',{attrs:{"type":"success","size":"small"},on:{"click":_vm.exportMajorInfo}},[_vm._v("导出数据 ")])],1)]},proxy:true}])},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"border":"","stripe":""}},[_c('el-table-column',{attrs:{"type":"index","label":"ID","width":"60","align":"center"}}),_c('el-table-column',{attrs:{"prop":"examineNo","label":"录取编号","width":"150"}}),_c('el-table-column',{attrs:{"prop":"stuName","label":"姓名","width":"170"}}),_c('el-table-column',{attrs:{"prop":"sexType","label":"性别","width":"60","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(Number(row.sexType) === 1)?_c('span',[_vm._v("男")]):_c('span',[_vm._v("女")])]}}])}),_c('el-table-column',{attrs:{"prop":"campusName","label":"现校区","width":"100","align":"center"}}),_c('el-table-column',{attrs:{"label":"专业类型","width":"100","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$http.getParamName(row.toMajorType, _vm.$store.state.systemParam.majorType))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"fromMajorName","label":"原专业","width":"150"}}),_c('el-table-column',{attrs:{"prop":"toMajorName","label":"现专业","min-width":"200"}}),_c('el-table-column',{attrs:{"prop":"className","label":"现班级","min-width":"240"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.className || '未绑定')+" ")]}}])}),_c('el-table-column',{attrs:{"label":"学籍状态","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$http.getParamName(row.stuStatus, _vm.$store.state.systemParam.stuStatus))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"操作时间","width":"160","align":"center","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$moment(row.createTime).format('YYYY-MM-DD HH:mm'))+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }