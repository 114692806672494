<template>
  <table-view
    :total="total"
    :query-info.sync="queryInfo"
    @reload-table="renderTable">
    <template #header>
      <view-search-form
        keyword-placeholder="姓名、录取编号"
        :queryInfo.sync="queryInfo"
        :tool-list="['grade', 'stuStatus', 'keyword']"
        @on-search="renderTable(1)">
        <template #pre-form>
          <el-form-item
            label="是否转过专业"
            label-width="100">
            <el-select v-model="queryInfo.changeMajorFlag" size="small" clearable style="width: 90px;">
              <el-option :value="0" label="否" />
              <el-option :value="1" label="是" />
            </el-select>
          </el-form-item>
        </template>
      </view-search-form>
      <div class="header-button">
        <el-button type="success" size="small" @click="exportMajorInfo"
        >导出数据
        </el-button>
        <el-button
          type="primary"
          plain
          size="small"
          icon="el-icon-printer"
          :disabled="!stuInfo.length"
          @click="batchPrint"
        >批量打印
        </el-button>
      </div>
    </template>
    <!-- body -->
    <el-table :data="tableData" style="width: 100%"
              @selection-change="selectStudent"
              border stripe>
      <el-table-column type="selection" width="40" />
      <el-table-column type="index" label="ID" width="60" align="center" />
      <el-table-column prop="examineNo" label="录取编号" width="150" />
      <el-table-column prop="stuName" label="姓名" width="120" />
      <el-table-column prop="sexType" label="性别" width="60" align="center">
        <template v-slot="{ row }">
          <span v-if="Number(row.sexType) === 1">男</span>
          <span v-else>女</span>
        </template>
      </el-table-column>
      <el-table-column prop="campusName" label="现校区" width="100" align="center" />
      <el-table-column label="专业类型" width="100" align="center">
        <template v-slot="{ row }">
          {{ $http.getParamName(row.majorType, $store.state.systemParam.majorType) }}
        </template>
      </el-table-column>
      <el-table-column prop="originalMajorName" label="原专业" width="150" />
      <el-table-column prop="majorName" label="现专业" width="150" />
      <el-table-column label="学籍状态" width="120">
        <template v-slot="{ row }">
          {{ $http.getParamName(row.stuStatus, $store.state.systemParam.stuStatus) }}
        </template>
      </el-table-column>
      <el-table-column prop="className" label="班级" width="150">
        <template v-slot="{ row }">
          {{ row.className || '未绑定' }}
        </template>
      </el-table-column>
      <el-table-column prop="remarks" label="备注" min-width="100"/>
      <el-table-column label="操作" min-width="200" fixed="right">
        <template v-slot="{row}">
          <el-button
            v-permission="['major:transfer']"
            type="primary"
            size="mini"
            @click="handleShowDialog(row)"
          >转专业
          </el-button>
          <el-button
            v-if="row.majorId !== row.originalMajorId "
            type="primary"
            icon="el-icon-printer"
            size="mini"
            @click="printChangeMajor(row)"
          >打印
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 转专业 -->
    <addTransferMajor ref="dialogRef" :visible.sync="visibleDialog" v-if="visibleDialog" @on-close="renderTable" />
    <printTransferMajor
      :printData="printData" />
    <batchPrintTransferMajor
      :printData="batchPrintData" />
  </table-view>
</template>

<script>
import { getStudentList } from '@/api/student'
import addTransferMajor from './addTransferMajor.vue'
import printTransferMajor from './printTransferMajor.vue'
import batchPrintTransferMajor from './batchPrintTransferMajor.vue'
import tableView from '@/vue/mixins/table-view'
import { copyData } from '@/utils/common-js'

export default {
  name: 'majorTransferView',
  components: {
    addTransferMajor,
    printTransferMajor,
    batchPrintTransferMajor
  },
  mixins: [tableView],
  data() {
    return {
      printData: {
        id: '',
        title: '',
        row: {},
        isOpen: false,
        type: ''
      },
      batchPrintData: {
        title: '',
        row: {},
        isOpen: false,
        type: ''
      },
      queryInfo: {
        collegeId: this.$store.state.login.userInfo.collegeId || null,
        changeMajorFlag: null,
        stuStatus: 1,
        grade: new Date().getFullYear()
      },
      stuInfo: []
    }
  },
  methods: {
    // 获取学生列表
    async renderTable(pageNum) {
      await this.getTableData(getStudentList, pageNum)
    },
    async handleShowDialog(row) {
      await this.showDialog(row.id)
      copyData(row, this.$refs.dialogRef.userInfo)
      this.$nextTick(() => {
        this.$refs.dialogRef.userInfo.origin = {
          collegeId: row.collegeId
        }
      })
    },
    // 选择学生
    selectStudent(stuIds) {
      this.stuInfo = stuIds
    },
    // 导入老生信息
    importOldStudent(file) {
      this.loading = true
      const data = new FormData()
      data.append('file', file.raw)
      this.$http.importExcelHttp.importOldStudent(data).then((res) => {
        this.loading = false
        if (res.data.success) {
          this.$message.success(res.data.msg || '导入成功')
          this.getStudentList()
        } else {
          this.$message.error(res.data.msg || '导入失败，请重新导入')
        }
      })
    },
    printChangeMajor(row) {
      this.printData.isOpen = true
      this.printData.id = row.id
      this.printData.row = row
    },
    batchPrint() {
      const students = this.stuInfo.filter(i => i.majorId !== i.originalMajorId)
      if (students.length === 0) {
        this.$message.error('请选择已经有转专业的同学!')
        return
      }
      this.batchPrintData.isOpen = true
      this.batchPrintData.rows = students
    },
    //   批量导出
    async exportMajorInfo() {
      let _exportInfo = null
      if (this.stuInfo.length) {
        _exportInfo = {
          stuIds: this.stuInfo.map(({ id }) => id)
        }
      } else {
        let _continueFlag = true
        if (this.queryInfo.changeMajorFlag === 0) {
          await this.$confirm('是否确认导出【未转专业】的学生？').then().catch(() => {
            _continueFlag = false
          })
        }
        if (!_continueFlag) return
        _exportInfo = {
          changeMajorFlag: this.queryInfo.changeMajorFlag,
          grade: this.queryInfo.grade,
          keyword: this.queryInfo.keyword,
          stuStatus: this.queryInfo.stuStatus
        }
      }
      this.$http.exportExcelHttp
        .exportMajorTransferInfo(_exportInfo)
        .then((res) => {
          this.$message.success('导出成功!')
        })
    }
  }
}
</script>
