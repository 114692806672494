<template>
  <div class="major-transfer">
    <div class="major-transfer-tabs">
      <el-radio-group v-model="tableType" style="width: 200px;" size="small">
        <el-radio-button label="transfer">转专业</el-radio-button>
        <el-radio-button label="record">转专业记录</el-radio-button>
      </el-radio-group>
    </div>
    <major-transfer-view v-if="tableType==='transfer'" />
    <transfer-record v-if="tableType==='record'" />
  </div>
</template>

<script>
import MajorTransferView from '@/views/pages/studentManage/major-transfer/components/majorTransferView.vue'
import TransferRecord from '@/views/pages/studentManage/major-transfer/components/transferRecord.vue'

export default {
  name: 'majorTransfer',
  components: {
    TransferRecord,
    MajorTransferView
  },
  data() {
    return {
      tableType: 'transfer'
    }
  }
}
</script>

<style lang="scss" scoped>
.major-transfer {
  .major-transfer-tabs {
    padding: 14px 0 0 14px;
    background: #fff;
  }
}
</style>
