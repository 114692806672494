<template>
  <div class="addAppVersion-dialog">
    <base-dialog
      :model="printData.isOpen"
      @close="closeDialogHandle"
      width="50%"
      :title="printData.title"
      marginTop="5vh"
      maxHeight="80vh"
    >
      <template #body>
        <div id="changeMajorTemp">
          <div class="page-body">
            <div class="header">
              <h1>{{ printData.row.grade }}级转专业申请表</h1>
            </div>
            <div class="content">
              <div class="table-body">
                <table class="table" width="100%" border="1">
                  <tr>
                    <td width="15%" colspan="2" class="textCenter" style="width: 70px;text-align: center">姓名</td>
                    <td width="15%" colspan="2" style=";text-align: center">
                      <span>{{ printData.row.stuName }}</span>
                    </td>
                    <td width="15%" colspan="1" class="textCenter" style="width:30px;text-align: center">性别</td>
                    <td width="15%" colspan="1" style="width:20px;text-align: center" >
                      <span>{{ printData.row.sexType === 1 ? '男' : '女' }}</span>
                    </td>
                    <td width="15%" colspan="2" class="textCenter" style="width: 90px;text-align: center">录取专业</td>
                    <td width="15%" colspan="4" style="width: 400px;text-align: center">
                      <span >{{ printData.row.originalMajorName }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" class="textCenter" style=";text-align: center">身份证号</td>
                    <td colspan="4" style=";text-align: center">
                      <span>{{ printData.row.idNumber }}</span>
                    </td>
                    <td colspan="2" class="textCenter" style=";text-align: center">拟转专业</td>
                    <td colspan="4" style="width: 400px;text-align: center">
                      <span>{{ printData.row.majorName }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" class="textCenter">申请理由</td>
                    <td colspan="10" >
                      <li style="height: 140px"></li>
                      <li class="textRight">
                        <span style="margin-right: 120px">学生签名：</span>
                        <span style="margin-right: 120px">家长签名：</span>
                      </li>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" class="textCenter">转出二级学院意见</td>
                    <td colspan="10">
                      <li style="height: 140px"></li>
                      <li class="textRight">
                        <span style="margin-right: 120px">院长签名：</span>
                        <span style="margin-right: 10px">日期：&#12288;&#12288;&#12288;&#12288;年&#12288;&#12288;月&#12288;&#12288;日</span>
                      </li>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" class="textCenter">转入二级学院意见</td>
                    <td colspan="10">
                      <li style="height: 140px"></li>
                      <li class="textRight">
                        <span style="margin-right: 120px">院长签名：</span>
                        <span style="margin-right: 10px">日期：&#12288;&#12288;&#12288;&#12288;年&#12288;&#12288;月&#12288;&#12288;日</span>
                      </li>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" class="textCenter">教务处意见</td>
                    <td colspan="10">
                      <li style="height: 140px"></li>
                      <li class="textRight">
                        <span style="margin-right: 120px">签名：</span>
                        <span style="margin-right: 10px">日期：&#12288;&#12288;&#12288;&#12288;年&#12288;&#12288;月&#12288;&#12288;日</span>
                      </li>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" class="textCenter">备注</td>
                    <td colspan="10">
                      <li style="height: 120px"></li>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <el-button @click="printData.isOpen = false">取 消</el-button>
        <el-button type="primary" plain icon="el-icon-printer" @click="printSignForm">打 印</el-button>
      </template>
    </base-dialog>
  </div>
</template>

<script>
export default {
  name: 'printTransferMajor',
  props: {
    printData: {
      type: Object
    }
  },
  data () {
    return {
      formData: {},
      init: false,
      TEMPLATE_HTML_URL: `${process.env.BASE_URL}print/changeMajorTemp.html`
    }
  },
  methods: {
    // 关闭弹窗
    closeDialogHandle () {
      this.printData.isOpen = false
    },
    nodeToString (node) {
      let tmpNode = document.createElement('div')
      tmpNode.appendChild(node.cloneNode(true))
      const str = tmpNode.innerHTML
      tmpNode = node = null
      return str
    },
    /* 打印报名表单 */
    printSignForm () {
      const report = document.querySelector('#changeMajorTemp')
      const iframe = document.createElement('iframe')
      const iframeStyle =
        'border:0;position:absolute;width:0px;height:0px;right:0px;top:0px;'
      iframe.style.cssText = iframeStyle
      const id = new Date().getTime()
      iframe.id = id
      iframe.src = this.TEMPLATE_HTML_URL
      document.body.appendChild(iframe)
      iframe.onload = () => {
        iframe.contentDocument.querySelector('#changeMajorForm').innerHTML =
          this.nodeToString(report)
        setTimeout(() => {
          iframe.contentWindow.print()
        }, 2000)
        setTimeout(() => {
          iframe.remove()
        }, 2000)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#changeMajorTemp {
  .page-body {
    .flexBox {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .header {
      @extend .flexBox;
      flex-direction: column;
      padding-bottom: 40px;
    }

    .content {
      padding-bottom: 20px;
      .table-body {
        .table {
          width: 100%;
          border-collapse: collapse;

          tr {
            .textCenter {
              text-align: center;
            }

            .textRight {
              text-align: right;
            }

            .flex-between {
              display: flex;
              justify-content: space-between;
            }

            td {
              padding: 0 10px;
              font-size: 14px;
              line-height: 40px;
              height: 40px;
            }
          }
        }
      }
    }
  }
}
</style>
